@import "../../../../Assets/styles/index.scss";

.about {
    --padding: 105px 0 200px;
    --gap: 20px;
    --fsSubTit: 18px;
    --fsTit: 40px;
    --fsDetail: 18px;
    --paddingTit: 30px 0 50px;
    --paddingLeftContent: 336px;
    --grid: 60% auto;
    padding: var(--padding);
    display: grid;
    grid-template-columns: var(--grid);
    column-gap: var(--gap);

    &-content {
        display: flex;
        flex-direction: column;
        padding-left: var(--paddingLeftContent);

        .subTit {
            font-family: $pretendardSemiBold;
            font-size: var(--fsSubTit);
            color: $cl-primary;
        }

        .title {
            font-family: $pretendardSemiBold;
            font-size: var(--fsTit);
            padding: var(--paddingTit);
            line-height: 1.3;
            display: flex;
            align-items: flex-start;
            // transform: translateX(-46px); //37 + 9
            // img {
            //     margin-top: 9px;
            //     margin-right: 9px;
            //     width: 37px;
            //     height: 37px;
            // }
        }

        .detail {
            line-height: 1.6;
            font-size: var(--fsDetail);
        }
    }

    &-img {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    @media screen and (max-width: 1600px) {
        --paddingLeftContent: 50px;
        // --grid: 50% 50%;
    }

    @media screen and (max-width: 1023px) {
        --padding: 82px 0 80px;

        display: flex;
        flex-direction: column;

        &-img {
            padding: 30px 20px;
        }

    }

    @media screen and (max-width: 767px) {
        --padding: 42px 0 40px;
        --fsSubTit: 16px;
        --fsTit: 22px;
        --paddingTit: 14px 0 30px;
        --paddingLeftContent: 40px;

        &-content {
            padding: 20px;
            .title {
                display: flex;
                justify-content: flex-start;
                // font-size: 13px;
            }
            .detail {
                font-size: 16px;
                word-break: keep-all;
                br {
                    display: none;
                }
            }
        }

    }
}