.intro {
    --padding: 140px 25px;
    --heightImg: 547px;
    --maxWidth: 1480px;
    --ptImg: 35px;
    background-color: #ffecdf;
    &-container {
        padding: var(--padding);
        max-width: var(--maxWidth);
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        &-img {
            padding-top: var(--ptImg);
            height: var(--heightImg);
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        --padding: 60px 25px;
        --heightImg: 500px;
    }

    @media screen and (max-width: 767px) {
        --padding: 50px 20px;
        --ptImg: 30px;
        --heightImg: 400px;
    }

}