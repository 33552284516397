@import "../../../../Assets/styles/index.scss";

.operation {
    --padding: 140px 25px;
    --gap: 20px;
    --fsSubTit: 18px;
    --fsTit: 40px;
    --fsDetail: 20px;
    --paddingTit: 30px 0 50px;
    --maxWidth: 1480px;
    --heightImg: 700px;
    --paddingImg: 70px 0 0;
    --maxWidthImg: 491px;
    background-color: #e9f4ee;
&-container {
    padding: var(--padding);
    display: grid;
    grid-template-columns: 60% 40%;
    column-gap: var(--gap);
    max-width: var(--maxWidth);
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    
    &-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        .subTit {
            font-family: $pretendardSemiBold;
            font-size: var(--fsSubTit);
            color: $cl-primary;
        }
        .title {
            font-family: $pretendardSemiBold;
            font-size: var(--fsTit);
            padding: var(--paddingTit);
            line-height: 1.3;
        }
        .detail {
            font-size: var(--fsDetail);
            &-item {
                display: flex;
                align-items: center;
                padding-top: 20px;
                img {
                    width: 21px;
                    height: 21%;
                }
            }
            span {
                margin-left: 9px;
            }
        }
    }

    &-img {
        height: var(--heightImg);
        img {
            width: 100%;
        height: 100%;
        object-fit: contain;
        }
    }
}
   @media screen and (max-width: 1200px) {
    --padding: 70px 25px;
    --gap: 20px;
    --fsSubTit: 18px;
    --fsTit: 32px;
    --fsDetail: 18px;
    --paddingTit: 30px 0 30px;
    --heightImg: 600px;
    &-container { 
        &-content {
            .title {
               br {
                display: none;
               }
            }
            .detail {
                br {
                    display: none;
                }
            }
        }
    }
   }

   @media screen and (max-width: 767px) {
    --padding: 30px 20px;
    --gap: 20px;
    --fsSubTit: 18px;
    --fsTit: 25px;
    --fsDetail: 18px;
    --paddingTit: 30px 0 30px;
    --heightImg: 500px;
    --ptImg: 30px 0 30px;
    &-container { 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-content {
            .title {
               text-align: center;
            }
            .detail {
                text-align: center;
            }
        }

    }
   }

}