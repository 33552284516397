@import "../../../../Assets/styles/index.scss";

.technology {
    --maxWidth: 1480px;
    --padding: 180px 25px;
    --paddingTopList: 80px;
    --paddingImg: 48px 64px;
    --fsTit: 24px;
    --fsDetail: 16px;
    --ptDetail: 40px;
    margin: 0 auto; 
    &-container {
        padding: var(--padding);
        max-width: var(--maxWidth);
        margin: 0 auto;
        .list {
            padding-top: var(--paddingTopList);
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: space-around;
            align-items: center;
            .item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .img {
                    padding: var(--paddingImg);
                    display: inline-flex;
                    img {
                        width: 100px;
                        height: 100px;
                        object-fit: contain;
                    }
                }
                .title {
                    font-size: var(--fsTit);
                    font-family: $pretendardSemiBold;
                    line-height: 1.4;
                    color: $cl-primary;
                    white-space: pre-line;
                    text-align: center;
                }
                .detail {
                    white-space: pre-line;
                    padding-top: var(--ptDetail);
                    font-size: var(--fsDetail);
                    line-height: 1.6;
                    text-align: center;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
    --padding: 100px 20px;
    --paddingTopList: 60px;
    --paddingImg: 28px 34px;
    --fsTit: 20px;
    --fsDetail: 16px;
    &-container {
        .list {
            column-gap: 20px;
            .item {
                .detail {
                    white-space:unset
                }
            }
        }
    }

    }

    @media screen and (max-width: 767px) {
        --padding: 50px 20px;
        --paddingTopList: 30px;
        --paddingImg: 20px 20px;
        --fsTit: 20px;
        --fsDetail: 15px;
        --ptDetail: 10px;
        &-container {
            .list {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .item {
                    width: 80%;
                    padding-top: 30px;
                    .detail {
                        white-space:unset
                    }
                }
            }
        }
    
        }

}