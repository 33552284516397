@import "../../../Assets/styles/variables";

footer {
    --padding: 75px 80px 99px;
    --plContent: 150px;
    --fsMenuItem: 16.5px;
    --fsCopyRight: 14px;
    --ptAddressItem: 39px;
    background-color: #4c4c4c;
    color: #fff;

    .footer-container {
        margin: 0 auto;
        padding: var(--padding);
        display: flex;

        .logo {
            width: 186px;
            height: 38px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .content {
            flex: 1;
            padding-left: var(--plContent);
            font-family: $pretendardSemiBold;

            &-menu {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                &-item {
                    font-size: var(--fsMenuItem);

                    &:not(:first-child) {
                        padding-left: 20px;
                    }

                    a {
                        color: #fff;
                    }
                }
            }

            &-address {
                font-size: var(--fsMenuItem);
                padding-top: var(--ptAddressItem);

                p {
                    line-height: 1.6;
                }

                &-list {
                    display: flex;
                    padding-top: 5px;
                    flex-wrap: wrap;
                    .list-item {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        flex-wrap: nowrap;

                        &-tit {
                            font-family: $pretendardSemiBold;
                        }

                        &-detail {
                            font-family: $pretendardVariable;
                            padding-left: 4px;
                        }

                        &:not(:first-child) {
                            padding-left: 15px;
                        }
                    }

                }
            }

            &-copyright {
                font-size: var(--fsCopyRight);
                color: #999999;
                padding-top: 12px;
            }
        }
        .content-download{
            display: flex;
            width: 133px;
            height: 40px;
            padding: 12px 12px 12px 18px;
            background-color: #fff;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            p{
                color: black;
            }
            .icon{
                color: black;
            }
        }
       
    }

 @media screen and (max-width: 1300px) {
    --padding: 45px 30px 50px;
    --plContent: 30px;
    --fsMenuItem: 16.5px;
    --fsCopyRight: 14px;
    --ptAddressItem: 26px;
 }
 @media screen and (max-width: 845px) {
    --padding: 35px 20px 30px;
    --plContent: 0px;
    --ptAddressItem: 26px;

    .footer-container {
        margin: 0 auto;
        padding: var(--padding);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .content {
            padding-top: 30px;
            padding-bottom: 30px;
            &-menu {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                &-item {
                    width: 50%;
                    font-size: var(--fsMenuItem);
                    padding-top: 10px;

                    &:not(:first-child) {
                        padding-left: 0px;
                    }
                    &:nth-child(3n) {
                        padding-left: 0px;
                    }
                    &:nth-child(2n) {
                        text-align: right;
                    }

                    a {
                        color: #fff;
                    }
                }
            }
            &-address {
                padding-top: 30px;
                p {
                    line-height: 1.3;
                }
                &-list {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-top: 5px;
                    .list-item {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        flex-wrap: nowrap;

                        &-detail {
                            padding-left: 5px;
                        }

                        &:not(:first-child) {
                            padding-left: 0px;
                        }
                    }

                }
            }
        }
    }
 

 }

}