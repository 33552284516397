@import "../../../../Assets/styles/index.scss";

.contact {
    --paddingleftContent: 60px;
    --fsTit: 40px;
    --fsDetail: 18px;
    --paddingSubtit: 60px 0 25px;
    --pbDetail: 30px;
    --heightMap: 800px;
    --paddingSocial: 36px 0 15px;
    --sizeSocialIcon: 58px;

    display: grid;
    grid-template-columns: 1fr 1fr;

    &-map {
        iframe {
            width: 100%;
            height: var(--heightMap)
        }
    }
    &-content {
        padding-left: var(--paddingleftContent);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
            font-size: var(--fsTit);
            font-family: $pretendardSemiBold;
            text-transform: uppercase;

        }
        .subtit {
            font-size: var(--fsDetail);
            padding: var(--paddingSubtit);
            color: $cl-primary;
        }
        .address {
            font-size: var(--fsDetail);
            line-height: 1.4;
            padding-bottom: var(--pbDetail);
        }
        .contact-info {
            .item {
                font-size: var(--fsDetail);
                display: flex;
                align-items: center;
                padding-bottom: 8px;
                .item-title {
                    color: #999999;
                    padding-right: 5px;
                }
                .item-detail {
                    color: #333333;
                }
            }
        }
        .contact-media {
            padding: var(--paddingSocial);
            display: flex;
            align-items: center;
            li {
                img {
                    width: var(--sizeSocialIcon);
                    height: var(--sizeSocialIcon);
                }
            }
            .facebook {
                margin-left: 20px;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        --paddingleftContent: 30px;
        --fsTit: 30px;
        --fsDetail: 18px;
        --paddingSubtit: 40px 0 25px;
        --pbDetail: 30px;
        --heightMap: 500px;
    }

    @media screen and (max-width: 767px) {
        --paddingleftContent: 20px;
        --fsTit: 23px;
        --fsDetail: 16px;
        --paddingSubtit: 20px 0 20px;
        --pbDetail: 20px;
        --heightMap: 400px;
        --sizeSocialIcon: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-map {
            width: 100%;
            padding-bottom: 20px;
        }

        &-content {
           width: 100%;
        }
        .contact-media {
            padding-bottom: 36px;;
        }

    }

}