.notice {
  --padding: 30px 5% 60px;
  --gap: 20px;
  --fsSubTit: 18px;
  --fsTit: 32px;
  --fsDetail: 18px;
  --paddingTit: 30px 0 50px;
  --paddingLeftContent: 336px;
  --grid: 60% auto;
  --maxWidth: 1480px;
  padding: var(--padding);
  display: flex;
  column-gap: var(--gap);
  background: #fff;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  gap: 20px;
  max-width: var(--maxWidth);
  margin: 0 auto;

  h3 {
    font-size: 24px;
  }

  .ant-tabs {
    width: 100%;

    .ant-tabs-nav::before {
      border: none;
    }

    .ant-tabs-nav-wrap {
      justify-content: center;
    }

    .ant-tabs-tab {
      font-size: var(--fsTit);
      font-family: "Pretendard-SemiBold";

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #000;
      }

      .ant-tabs-tab-btn {
        color: #999;
      }
    }

    .ant-tabs-ink-bar {
      display: none !important;
    }
  }

  .ant-table {
    border-radius: 0;
    border-top: 1px solid #000;
    background: #e9f4ee;
  }

  .ant-table-wrapper {
    width: 100%;

    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        background: #e9f4ee;
      }
    }

    .ant-table-cell {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      border-top: 1px solid #ccc;
    }
  }
  tr.ant-table-row.ant-table-row-level-0 {
    cursor: pointer;
  }
  p {
    padding: 6px 0;

    @media screen and (max-width: 767px) {
      padding: 3px 0;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 767px) {
    --gap: 10px;
  }
}
