@import "../../../../Assets/styles/index.scss";

.hero-section {
    // --height: 881px;
    --paddingDetail: 20px 0 50px;
    --fsTit: 90px;
    --fsDetail: 40px;
    --heightSecondSlide: 414px;
    --paddingContentSecondSlide: 80px 20px 0 100px;
    height: var(--height);

    .swiper-wrapper {
        height: var(--height);
    }

    .swiper-slide {
        height: 100%;
    }

    .first-slide {

        height: auto;
        width: 100%;
        img{
            width: 100%;
            max-width: 100%;
        }
      
    }

    .second-slide {

        height: auto;
        width: 100%;
        img {
            width: 100%;
            max-width: 100%;
        }

    }

    @media screen and (max-width: 1200px) {
        // --height: 881px;
        --paddingDetail: 20px 0 50px;
        --fsTit: 60px;
        --fsDetail: 30px;
        --heightSecondSlide: 314px;
        --paddingContentSecondSlide: 80px 20px 0 100px;

        .first-slide {
            height: 100%;

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        // --height: 581px;
        --paddingDetail: 20px 0 30px;
        --fsTit: 30px;
        --fsDetail: 20px;
        --heightSecondSlide: 314px;
        --paddingContentSecondSlide: 30px 20px 0 40px;
    }

}