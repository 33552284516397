@import "../../../../Assets/styles/index.scss";

.application {
    --padding: 38px 25px;
    --fs: 30px;
    --prTit: 78px;
    --widthImg: 491px;
    --heightImg: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding);
    background-color: #f8f8fa;
    .title {
        font-size: var(--fs);
        font-family: $pretendardSemiBold;
        padding-right: var(--prTit);
    }
    img {
        // width: var(--widthImg);
        // height: var(--heightImg);
        object-fit: contain;
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
    --padding: 12px 20px;
    --fs: 22px;
    --prTit: 28px;
    --widthImg: 80%;
    --heightImg: 40px;
    .title {
        padding-bottom: 15px;
    }
    }
}