@import "../../../../Assets/styles/index.scss";

.mission {
    --height: 662px;
    --ptButton: 64px;
    height: var(--height);
    position: relative;
    &-img {
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        .button {
            padding-top: var(--ptButton);
        }
    }
    @media screen and (max-width: 767px) {
        --height: 462px;
    }
}