@import "../../../../Assets/styles/index.scss";

.invention {
    --maxWidth: 1480px;
    --padding: 180px 25px;
    --ptList: 90px;
    --ptListTitle: 20px;
    --fsTit: 18px;
    padding: var(--padding);
    max-width: var(--maxWidth);
    margin: 0 auto;
    .list {
        padding-top: var(--ptList);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 40px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .title {
            font-size: var(--fsTit);
            padding-top: var(--ptListTitle);
            text-align: center;
            font-family: $pretendardMedium;
        }
    }

    @media screen and (max-width: 1200px) {
        --padding: 70px 25px;
        --ptList: 50px;
        --ptListTitle: 23px;
        --fsTit: 18px;
    }

    @media screen and (max-width: 767px) {
        --padding: 40px 20px;
        --ptList: 30px;
        --ptListTitle: 10px;
        --fsTit: 16px;
        .list {
            grid-template-columns: 1fr;
            column-gap: 21px;
            row-gap: 30px;
        }
    }

}