@import "../../../Assets/styles/index.scss";

.header {
  top: 0px;
  right: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 80px;
  position: fixed;
  box-shadow: $box-shadow;
  transition: all 400ms;
  a {
    color: inherit;
    font-size: 16px;
    // transition: 400ms;
    cursor: pointer;
  }

  &_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    .header_title {
      width: 186px;
height: auto;
      font-weight: bold;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .list_menu {
      display: flex;
      margin-bottom: 0;
      padding-left: 25px;
      flex: 1;
      justify-content: flex-end;

      .menu_items {
        padding-left: 46px;

        .activeItemsPage {
          // color: $white-cl;
        }

      }
    }
  }


  .header_mobile_icon {
    display: none;
  }

  @media screen and (max-width: 1279px) {
    padding: 25px;

    &_menu {
      .list_menu {
        .menu_items {
          padding-left: 20px;

          a {
            font-size: 13px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 860px) {
    padding: 20px 20px;

    &_menu {
      justify-content: flex-start;

      .list_menu {
        display: none;
      }

      .header_title {
        width: 120px;

        img {
          object-fit: contain;
        }
      }

    }

    .header_login {
      display: none;
    }

    .header_mobile_icon {
      display: block;
      width: 30px;
      height: 30px;
      color: $white-cl
    }

  }
}


.header-mobile {
  position: fixed;
  background-color: #222;
  top: 0;
  right: 0;
  width: 350px;
  height: 100vh;
  z-index: 99;
  transform: translateX(100%);
  transition: 500ms;

  .icon-close {
    display: flex;
    padding: 20px 20px;
    justify-content: flex-end;

    span {
      color: $white-cl;
      font-size: 30px;
    }
  }

  .menu-mobile-items {
    .list_menu {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      .menu_items {
        padding-left: 46px;
        padding: 25px 0;
        text-align: center;

        a {
          color: $white-cl;
          font-size: 20px;
        }

      }
    }
  }

}

.openMenu {
  transform: translateX(0);
}